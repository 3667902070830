// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-account-details-tsx": () => import("./../src/pages/account-details.tsx" /* webpackChunkName: "component---src-pages-account-details-tsx" */),
  "component---src-pages-billing-details-tsx": () => import("./../src/pages/billing-details.tsx" /* webpackChunkName: "component---src-pages-billing-details-tsx" */),
  "component---src-pages-confirmation-tsx": () => import("./../src/pages/confirmation.tsx" /* webpackChunkName: "component---src-pages-confirmation-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

